import axios from 'axios'

export function getAgendaByEmail () {
  const email = localStorage.getItem('user')
  const role = localStorage.getItem('role')
  return axios.get('/agenda?email=' + email + '&role=' + role)
  .then(response => {
    return response.data
  })
}

export function getAgendaByIdUser (id) {
  return axios.get('/agenda/user/' + id)
  .then(response => {
    return response.data
  })
}

export function restriccionDays (payload) {
  return axios.post('/restriccion/days', payload)
  .then(response => {
    return response.data
  })
}

export function restriccionDate (payload) {
  return axios.post('/restriccion/date', payload)
  .then(response => {
    return response.data
  })
}

export function deleteRestriccionDate (id) {
  return axios.delete('/restriccion/' + id)
  .then(response => {
    return response.data
  })
}

export function updateAgenda (data) {
  return axios.put('/agenda', data)
  .then(response => {
    return response.data
  })
}

export function getCitasSala (sala, agenda) {
  return axios.get('/citas/sala?sala=' + sala + '&agenda=' + agenda)
  .then(response => {
    return response.data
  })
}

export function getCitasAgenda (agenda) {
  return axios.get('/citas/agenda/' + agenda)
  .then(response => {
    return response.data
  })
}

export function addCitas (cita) {
  cita.email = localStorage.getItem('user')
  return axios.post('/citas', cita)
  .then(response => {
    return response.data
  })
}

export function updateCitas (cita) {
  cita.email = localStorage.getItem('user')
  return axios.put('/citas', cita)
  .then(response => {
    return response.data
  })
}

export function getCitasClienteByEmail () {
  const email = localStorage.getItem('user')
  return axios.get('/citas/cliente?email=' + email)
  .then(response => {
    return response.data
  })
}

export function getCitasClienteById (id) {
  return axios.get('/citas/cliente/' + id)
  .then(response => {
    return response.data
  })
}

export default {
  getAgendaByEmail,
  getAgendaByIdUser,
  restriccionDays,
  restriccionDate,
  deleteRestriccionDate,
  updateAgenda,
  getCitasSala,
  getCitasAgenda,
  addCitas,
  updateCitas,
  getCitasClienteByEmail,
  getCitasClienteById,
}
